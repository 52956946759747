<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Fixed Accounts</h1>
      <!-- <div class=" flex justify-end items-end">
        <button
          class=" bg-blue-600 py-3 px-6 rounded-md  text-white"
          @click="downloadCsv"
        >
          Download CSV
        </button>
      </div> -->
      <div class="flex items-center mt-10">
        <input
          type="text"
          class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
          name=""
          placeholder="Search by account name, account number, ID"
          id=""
          v-model="search"
          title="Search by account name, account number, ID"
        />
        <button
          class="bg-blue-600 py-3 mx-5 px-6 rounded-md text-xs text-white"
          @click="searchRecords"
        >
          Search Records
        </button>
        <button
          @click="clearSearchRecords"
          class="bg-red-800 hover:bg-red-700 py-3 px-6 rounded-md text-xs text-white"
        >
          Reset
        </button>
      </div>
      <div class="flex bg-blue-50 p-5 mt-10">
        <div class="flex-2 inline-block time-filter">
          <p>From:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startDate"
          />
        </div>
        <div class="flex-2 inline-block time-filter">
          <p>To:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.endDate"
          />
        </div>
        <div class="flex mt-5">
          <!-- <button
            @click="fetchDatedRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearDateFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Reset
          </button> -->
          <button
            @click="downloadDatedCSV"
            class="block uppercase shadow bg-blue-800 hover:bg-blue-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Download
          </button>
        </div>
      </div>
      <div class="mt-14">
        <button
          class="bg-gray-300 text-black text-xs py-3 px-6 rounded-md mx-3 text-black mb-3 font-semibold"
          @click="whitelistSelected"
          v-if="check_auth"
        >
          Whitelist Selected ({{ selected.length }})
        </button>
        <button
          class="bg-black text-xs py-3 px-6 rounded-md text-white mb-3 font-semibold"
          @click="blacklistSelected"
          v-if="check_auth"
        >
          Blacklist Selected ({{ selected.length }})
        </button>
        <div class="mt-5" style="max-height: 600px; overflow: auto">
          <table class="shadow-lg bg-white w-full relative border-collapse">
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="11"
                style="background: #dee6f2"
              >
                Merchant Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
                v-if="check_auth"
              >
                <input type="checkbox" v-model="selectAll" />
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Merchant ID
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business Name
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Business Email
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Bank Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Account Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Bank Account
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Store
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Date Account Created
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Blacklist Status
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
                v-if="check_auth"
              >
                Action
              </th>
            </tr>
            <tr class="text-xs" v-for="(account, i) in filterAccounts" :key="i">
              <td v-if="check_auth">
                <input
                  type="checkbox"
                  :value="account._id"
                  v-model="selected"
                />
              </td>
              <td>{{ account.business_id }}</td>
              <td>{{ account.business_name }}</td>
              <td>{{ account.business_email }}</td>
              <td>{{ account.bank_name }}</td>
              <td>{{ account.custom_account_name }}</td>
              <td>{{ account.custom_account_number }}</td>
              <td>{{ account.store_details.store_name }}</td>
              <td>
                {{
                  moment(account.created_at).format("DD MMM YYYY - h:mm:ss a")
                }}
              </td>
              <td>{{ account.is_blacklisted }}</td>
              <td v-if="check_auth">
                <button
                  :class="
                    account.is_blacklisted === true
                      ? 'bg-gray-300 text-black text-xs'
                      : 'bg-red-900 text-white text-xs'
                  "
                  class="py-3 px-6 rounded-md"
                  @click="toggleBlacklist(account, !account.is_blacklisted)"
                >
                  {{
                    account.is_blacklisted === true ? "Whitelist" : "Blacklist"
                  }}
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div class="mt-3">
          <button
            @click="prevPage(currentPage)"
            :disabled="currentPage === 1 ? isDisabled : !isDisabled"
            class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
            :class="
              currentPage === 1
                ? 'bg-blue-50 text-black'
                : 'bg-blue-900 text-white'
            "
          >
            &laquo; Prev
          </button>

          <button
            @click="nextPage(currentPage)"
            class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
            v-if="filterAccounts.length > 99"
          >
            Next &raquo;
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { json2excel } from "js2excel";
import { GET_USER_BUSINESS_DATA } from "../browser-db-config/localStorage";
import authorizedUsers from "../config/authUsers";
import Loading from "./general-components/Loading.vue";
import mixpanel from "mixpanel-browser";
import { GET_USER_DATA } from "../browser-db-config/localStorage";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      selected: [],
      selectedWhitelist: [],
      currentPage: 1,
      isDisabled: true,
      dateData: null,
      dateFilterActive: false,
      searchActive: false,
      response: "",
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_FIXED_ACCOUNT_RES"]),
    filterAccounts() {
      //let y = this.GET_DOCUMENTS.data.filter((b) => b.status === x);

      return this.GET_FIXED_ACCOUNT_RES.data.items.fixAccount.filter(
        (account) =>
          account.custom_account_number.includes(this.search) ||
          account.custom_account_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          account.business_id
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          account.business_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          account.bank_name.toLowerCase().includes(this.search.toLowerCase()) ||
          account.store_details.store_name
            .toLowerCase()
            .includes(this.search.toLowerCase())
      );
      // this.documents = filtered;
    },
    selectAll: {
      get: function () {
        return this.GET_FIXED_ACCOUNT_RES.data.items.fixAccount
          ? this.selected.length ==
              this.GET_FIXED_ACCOUNT_RES.data.items.fixAccount.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          if (this.GET_FIXED_ACCOUNT_RES.data.items.fixAccount !== null) {
            this.GET_FIXED_ACCOUNT_RES.data.items.fixAccount.forEach(function (
              account
            ) {
              selected.push(account._id.toString());
            });
          }
        }

        this.selected = selected;
      },
    },
    check_auth() {
      const userEmail = GET_USER_BUSINESS_DATA()
        ? GET_USER_BUSINESS_DATA().email
        : "";
      return authorizedUsers.includes(userEmail);
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {
    mixpanel.init("18643d563023caee796b6682e5eada6c", {
      debug: true,
      track_pageview: true,
    });
    mixpanel.identify(GET_USER_DATA().name);
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.fetchRecords();
  },
  methods: {
    ...mapActions([
      "FETCH_FIXED_ACCOUNTS",
      "FETCH_DATED_FIXED_ACCOUNT",
      "DOWNLOAD_FIXED_ACCOUNT_CSV",
      "WHITELIST_BULK_FIXED_ACCOUNTS",
      "FETCH_PAGINATED_FIXED_ACCOUNT",
      "WHITELIST_BULK_FIXED_ACCOUNTS",
      "SEARCH_FIXED_ACCOUNT",
      "BLACKLIST_FIXED_ACCOUNTS",
    ]),
    async fetchRecords() {
      this.isLoading = true;
      let response = await this.$store.dispatch("FETCH_FIXED_ACCOUNTS");
      this.accounts = response.data;

      if (response.data) {
        this.isLoading = false;
      }
    },
    clearSearchRecords() {
      this.search = "";
      this.currentPage = 1;
      this.fetchRecords();
      this.searchActive = false;
    },
    async fetchDatedRecords() {
      if (
        this.dateData.startDate > this.dateData.endDate ||
        this.dateData.startDate === this.dateData.endDate
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        try {
          this.isLoading = true;
          this.dateFilterActive = true;
          let res = await this.$store.dispatch(
            "FETCH_DATED_FIXED_ACCOUNT",
            this.dateData
          );
          console.log("Testing 2");
          this.isLoading = false;
          if (res.status == true) {
            // alert("Backlist was successful");
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    clearDateFilter() {
      this.currentPage = 1;
      this.dateFilterActive = false;
      this.fetchRecords();
    },
    async downloadDatedCSV() {
      if (
        this.dateData.startDate > this.dateData.endDate ||
        this.dateData.startDate === this.dateData.endDate
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.isLoading = true;
        try {
          let res = await this.$store.dispatch(
            "DOWNLOAD_FIXED_ACCOUNT_CSV",
            this.dateData
          );

          this.isLoading = false;
          if (res.status == true) {
            // alert("Backlist was successful");
            window.open(res.data, "_blank");
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },

    async toggleBlacklist(account, status) {
      this.response = confirm(
        "Are you sure you want to blacklist this account?"
      );
      if (this.response === true) {
        this.isLoading = true;
        let data = { fixed_account_id: account._id, black_list_status: status };
        try {
          let res = await this.$store.dispatch(
            "BLACKLIST_FIXED_ACCOUNTS",
            data
          );
          if (res.status == true) {
            alert(res.message);
            //Track Event on MixPanel
            mixpanel.track("TA_ADMIN_FRAUD_BLACKLIST", {
              "Business Name": account.business_name,
              "Merchant Name": account.merchant_name,
              "Business Email": account.business_email,
              "Blacklist Operation": status,
            });
            // this.$router.go();
            this.fetchRecords();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}

        this.isLoading = false;
      }
    },
    async whitelistSelected() {
      this.response = confirm(
        "Are you sure you want to whitelist these accounts?"
      );
      if (this.response === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          const selecteObj = {
            fixed_account_id: el,
            black_list_status: false,
          };
          this.selectedWhitelist.push(selecteObj);
        });
        try {
          let res = await this.$store.dispatch(
            "WHITELIST_BULK_FIXED_ACCOUNTS",
            this.selectedWhitelist
          );
          if (res.status == true) {
            alert(res.message);
            //Track Event on MixPanel
            mixpanel.track("TA_ADMIN_FRAUD_BLACKLIST", {
              "Business Name": account.business_name,
              "Merchant Name": account.merchant_name,
              "Business Email": account.business_email,
              "Blacklist Operation (Bulk)": "whitelist",
            });
            this.$router.go();
            // this.fetchRecords();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}

        //this.isLoading = true;
        //let data = { fixed_account_id: business_id, black_list_status: false };
      }
    },

    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let payload = {
        page: currPage - 1,
        filter: this.dateFilterActive,
        date: this.dateData,
        searchFilter: this.searchActive,
        search: this.search,
      };
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_FIXED_ACCOUNT",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let payload = {
        page: currPage + 1,
        filter: this.dateFilterActive,
        date: this.dateData,
        searchFilter: this.searchActive,
        search: this.search,
      };
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_FIXED_ACCOUNT",
        payload
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },

    async blacklistSelected() {
      this.response = confirm(
        "Are you sure you want to blacklist these accounts?"
      );
      if (this.response === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          const selecteObj = {
            fixed_account_id: el,
            black_list_status: true,
          };
          this.selectedWhitelist.push(selecteObj);
        });
        try {
          let res = await this.$store.dispatch(
            "WHITELIST_BULK_FIXED_ACCOUNTS",
            this.selectedWhitelist
          );
          if (res.status == true) {
            alert(res.message);
            //Track Event on MixPanel
            mixpanel.track("TA_ADMIN_FRAUD_BLACKLIST", {
              "Business Name": account.business_name,
              "Merchant Name": account.merchant_name,
              "Business Email": account.business_email,
              "Blacklist Operation (Bulk)": "blacklist",
            });
            this.$router.go();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}

        //this.isLoading = true;
        //let data = { fixed_account_id: business_id, black_list_status: false };
      }
    },
    async searchRecords() {
      this.isLoading = true;
      this.searchActive = true;
      let payload = { filter: this.searchActive, search: this.search };
      try {
        let res = await this.$store.dispatch("SEARCH_FIXED_ACCOUNT", payload);

        this.isLoading = false;
        if (res.status) {
          this.isLoading = false;
          if (this.GET_FIXED_ACCOUNT_RES.data.items.fixAccount === null) {
            console.log("Testing again");
            alert("Couldn't find " + this.search);
            this.fetchRecords();
          }
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
